import AkomapsLogo from "@studytools/core/ui/components/logo/AkoMapsLogo";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React, { FC, useState } from "react";
import { Menu, X } from "react-feather";
import { LoginLink } from "../links/login";
import { login, signUp } from "../../common/links/signup";
import { Grid, Brand, Mobile, Nav, NavItem, NavList, NavListWrapper, StyledContainer } from "./style";
import Button from "@studytools/core/ui/components/Button";
import { Typography } from "@material-ui/core";

const NAV_ITEMS = [
  {
    name: "Courses",
    link: "/explore",
  },
  {
    name: "About",
    link: "/about",
  },
];

type Props = {
  props?;
};

const Navigation: FC<Props> = ({ props }) => {
  const [mobileMenuState, setMobileMenuState] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuState(!mobileMenuState);
  };
  const handleSignup = event => {
    event.preventDefault();
    signUp();
  };

  const handleLogin = event => {
    event.preventDefault();
    login();
  };

  const getNavAnchorLink = item => (
    <span>
      <AnchorLink to={`${item.link}`} title={`${item.name}`} stripHash>
        {item.name}
      </AnchorLink>
    </span>
  );

  const getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile} onClick={toggleMobileMenu}>
      <NavList>
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem.name}>{getNavAnchorLink(navItem)}</NavItem>
        ))}
        <NavItem key="signup" style={{ width: 176 }}>
          <Button onClick={handleSignup} fill="white">
            <Typography variant="h6">Sign up</Typography>
          </Button>
        </NavItem>
        <NavItem key="login">
          <LoginLink onClick={handleLogin}>Login</LoginLink>
        </NavItem>
      </NavList>
    </NavListWrapper>
  );

  return (
    <Nav {...props}>
      <StyledContainer id="nav">
        <Brand>
          <AnchorLink to="/#home" stripHash>
            <AkomapsLogo />
          </AnchorLink>
        </Brand>
        <Mobile hide={false}>
          <Button fill="none" onClick={toggleMobileMenu}>
            {mobileMenuState ? <X size={24} /> : <Menu size={24} />}
          </Button>
        </Mobile>

        <Mobile hide>{getNavList({})}</Mobile>
      </StyledContainer>
      {mobileMenuState && (
        <Mobile hide={false} style={{ height: "100vh", width: "100vw", position: "absolute" }}>
          <Grid container alignItems="center" direction="column">
            {getNavList({ mobile: true })}
          </Grid>
          <div
            style={{ backgroundColor: "rgba(0,0,0,0.6)", backdropFilter: "blur(4px)", height: "100%" }}
            onClick={toggleMobileMenu}
          />
        </Mobile>
      )}
    </Nav>
  );
};

export default Navigation;
